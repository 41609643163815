import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
} from '@angular/forms'
import {Directive, Injector, Input, ViewChild} from '@angular/core'

// Control Value Accessor pattern utility for simpler code when implementing a custom field for an angular form
// inspired from https://medium.com/angular-in-depth/dont-reinvent-the-wheel-when-implementing-controlvalueaccessor-a0ed4ad0fafd

@Directive()
export class ControlValueAccessorConnectorDirective implements ControlValueAccessor {
  @ViewChild(FormControlDirective, {static: true})
  formControlDirective: FormControlDirective | undefined

  @Input()
  formControl: UntypedFormControl | undefined

  @Input()
  formControlName: string | undefined

  private memoizedControlName: string | undefined

  constructor(private readonly injector: Injector) {}

  get control(): AbstractControl | undefined | null {
    return this.formControl ?? this.controlContainer?.control?.get(this.formControlName || '')
  }

  get controlName(): string | undefined {
    return this.formControlName ?? this.getMemoizedControlName()
  }

  get controlContainer(): ControlContainer | undefined | null {
    return this.injector.get(ControlContainer, undefined, {optional: true})
  }

  registerOnTouched(fn: unknown): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn)
  }

  registerOnChange(fn: unknown): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn)
  }

  writeValue(obj: unknown): void {
    this.formControlDirective?.valueAccessor?.writeValue(obj)
  }

  setDisabledState(isDisabled: boolean): void {
    const accessor = this.formControlDirective?.valueAccessor
    if (accessor?.setDisabledState) {
      accessor.setDisabledState(isDisabled)
    }
  }

  private getMemoizedControlName(): string | undefined {
    if (this.memoizedControlName) {
      return this.memoizedControlName
    }

    if (this.control && !this.control?.parent) {
      throw Error('This control should a child of a FormGroup.')
    }

    this.memoizedControlName = Object.entries(this.control?.parent?.controls ?? [])
      .map(([name, control]) => ({name, control}))
      .find(c => c.control === this.control)?.name
    return this.memoizedControlName
  }
}
